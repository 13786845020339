<template>
  <div class="flex items-center mb-2 gap-3" v-if="label">
    <label for="" class="flex gap-2">
      {{ label }}
      <span v-if="required" class="text-red-500"> *</span>
    </label>
    <Tooltip
      v-if="showTooltip"
      text="Hover me"
      :tooltipText="tooltipText"
      position="right"
      img="icons/tooltip-icon.svg"
    />
  </div>
  <div class="custom-select" :class="{'cursor-not-allowed opacity-75': disabled, [customClass]: true }" :tabindex="tabindex" v-click-outside="closeDropdown">
    <div class="selected" :class="{ 'open': open }" @click="toggleDropdown">
      <div
        class="
          placeholder
          inline-block
          text-gray-600
          font-medium
          mr-auto
          leading-5
          w-10/12
          py-3
        "
        v-if="selected == undefined || selected == null || showAll"
      >
        {{ $t('filters.tableFilterSelectPlaceholder') }}
      </div>
      <div
        class="
          inline-block
          text-gray-600
          font-medium
          mr-auto
          leading-5
          w-10/12
          py-3
        "
        v-else
      >
        {{ selected?.name }}
      </div>
      <img
        src="@/assets/img/icons/icon-down-arrow.svg"
        alt=""
        class="inline-block absolute right-0 mr-4 arrow-image"
      />
    </div>
    <div class="items font-archivo" :class="{ selectHide: !open }">
      <form id="search" class="search w-full px-2 my-2 flex gap-2" v-if="searchable">
        <img src="@/assets/img/icons/search-icon.svg" alt="icon">
        <input name="query" v-model="searchQuery" :placeholder="$t(searchText)" class="w-full" ref="search">
      </form>
      <div class="items-wrapper" v-if="filteredOptions && filteredOptions.length !== 0">
        <div
          v-for="(option, i) of filteredOptions"
          :key="i"
          @click="
            selected = option;
            open = false;
            $emit('selection-changed', option);
          "
          class="my-2 leading-5"
        >
        <div>   {{ option.name }}</div>
          <div v-if="option.desc" class="mt-1">{{ option.desc }}</div>
        </div>
      </div>
      <div v-else class="no-results px-12">
        <label> {{$t(noSearchResults)}} </label>
      </div>
      <div
          v-if="addNewText !== ''"
          class="items-new-user cursor-pointer"
          @click.stop="createNew"
          :disabled="lockAddNewTag"
        >
          <span v-if="!createNewProjectInstantVisible">{{ $t(addNewText) }}</span>
          <span v-else class="flex flex-col w-full items-left">
            <div class="flex flex-col text-dark">
                <span>{{ $t('filters.tableFilterSelect.selectProduct') }}</span>
                <FormKit
                  v-model="newInstantProduct"
                  type="radio"
                  :options="localProductTypes"
                  :classes="{
                    'options': 'flex gap-3 items-center',
                  }"
                />
            </div>
            <div class="flex gap-3 items-center">
              <input
                  type="text"
                  name="name"
                  id="id"
                  v-model="newInstantValue"
                  class="border w-full rounded-lg p-2 text-dark focus:outline-none"
                  placeholder="Name"
                  ref="newTagInput"
                  @keyup.enter="addValueInstantly"
                >
                <a @click="addValueInstantly">{{ $t('filters.tableFilterSelect.add') }}</a>
            </div>
          </span>
        </div>
    </div>
  </div>
</template>
<script>
import Tooltip from '@/components/molecules/Tooltip.vue';
import { ProductGroupTypes } from '@/helpers/productGroupTypes';
import {mapState} from "vuex";

export default {
    props: {
        label: {
            type: String,
            default: "",
            required: false,
        },
        options: {
            type: Array,
            required: true,
        },
        placeholder: {
            type: String,
            default: "Tous",
        },
        tabindex: {
            type: Number,
            required: false,
            default: 0,
        },
        value: {
            type: String,
            default: null,
        },
        showAll: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        selectedByDefault: {
            type: Object,
            default: null,
        },
        class: {
          type: String,
          default: null,
        },
        showTooltip: {
            type: Boolean,
            default: false,
        },
        tooltipText: {
            type: String,
            default: "Your tooltip text",
            required: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        addNewText: {
          type: String,
          default: "",
        },
        lockAddNewTag: {
          type: Boolean,
          default: false,
        },
        createNewProjectInstantMode: {
          type: Boolean,
          default: false,
      },
        // instanceWithProducts: {
        //   type: Boolean,
        //   default: false,
        // },
        searchable: {
          type: Boolean,
          default: false,
        },
        searchText: {
          type: String,
          default: "filters.tableFilterSelect.searchText",
        },
        noSearchResults: {
          type: String,
          default: "filters.tableFilterSelect.noSearchResults",
        },
    },
    emits: ["selection-changed"],
    data() {
        return {
          open: false,
          selected: null,
          createNewProjectInstantVisible: false,
          newInstantValue: '',
          newInstantProduct: '1',
          searchQuery: "",
        };
    },
  methods: {
    setSelected() {

      if (this.options === undefined || this.options === null) {
        return null;
      }

      this.selected = this.options?.find((option) => option.value === undefined
        ? option.name == this.value
        : option.value == this.value);
    },
    toggleDropdown() {
      if (!this.disabled) {
        this.open = !this.open;
        this.createNewProjectInstantVisible = false;

        if (this.open) {
            this.$nextTick(() => {
              this.$refs.search.focus();
            });
        }
      }
    },
    closeDropdown() {
      this.open = false;

      // clear search query
      this.searchQuery = "";
    },
    createNew() {
      console.log('create new')
      if (this.lockAddNewTag) return;

      if (this.createNewProjectInstantMode) {
        this.createNewProjectInstantVisible = true;
        this.$nextTick(() => {
          this.$refs.newTagInput.focus();
        });
      } else {
        this.closeDropdown();
        this.$emit("createNew");
      }
    },
      addValueInstantly() {
      if (this.newInstantValue) {
        this.$emit("createNew", this.newInstantValue, this.newInstantProduct);
        this.newInstantValue = '';
        this.createNewProjectInstantVisible = false;
        this.closeDropdown();
      }
    },
  },
  computed: {
    ...mapState(['auth', 'organizations']),
    organization() {
      return this.organizations.find(
          (organization) => organization.id === this.auth.currentUser.organization_id
      )
    },
    customClass() {
      return this.class;
    },
    localProductTypes() {
      const currentOrgProductGroups = this.organization.product_groups.map((el) => el.name);

      return Object.keys(ProductGroupTypes)
        .filter((el) => currentOrgProductGroups.includes(el))
        .map((type) => {
          return {
            label: this.$t('productGroups.' + type),
            value: ProductGroupTypes[type],
          };
        });
    },
    filteredOptions() {
      if (this.searchQuery) {
        return this.options.filter((option) => option.name.toLowerCase().includes(this.searchQuery.toLowerCase()));
      }
      return this.options;
    },
  },
  watch: {
    value(newValue) {
        this.setSelected();
    },
    selectedByDefault(newValue) {
      this.selected = newValue;
      this.$emit('selection-changed', this.selected);
    },
    options(newValue) {
      this.setSelected();
    },
  },
  mounted() {
      this.setSelected();
      if (this.selectedByDefault) {
        this.selected = this.selectedByDefault;
        this.$emit('selection-changed', this.selected);
      }
  },
  components: { Tooltip },
};
</script>

<style scoped>
.default {
  color: #595959;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
}

.custom-select {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  height: auto;
  min-height: 47px;
}

.custom-select .selected {
  height: auto;
  min-height: 35px;
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #E5E7EB;
  color: #666;
  padding-left: 0.7em;
  /* cursor: pointer; */
  user-select: none;
  min-height: 41px !important;
}

.custom-select .selected.open {
  border: 1px solid rgb(86, 208, 138);
  border-radius: 6px 6px 6px 6px;
  margin-bottom: 8px;
}

.custom-select.not-valid .selected{
  border-color: #f57f6c;
}

.custom-select .selected > div{
   text-overflow: ellipsis;
   overflow: hidden;
   white-space: nowrap;
   padding-bottom: 0;
}
/*
  .custom-select .selected:after {
    position: absolute;
    content: "";
    top: 22px;
    right: 1em;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-color: #fff transparent transparent transparent;
  } */

.custom-select .items {
  border-radius: 6px 6px 6px 6px;
  overflow: hidden;
  border-right: 1px solid lightgray;
  border-left: 1px solid lightgray;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  position: absolute;
  background-color: #fff;
  left: 0;
  right: 0;
  z-index: 9;
  line-height: 24px;
}

.filter-container .custom-select .items{
  width: 120%;
}

.custom-select .items-wrapper div {
  color: #595959;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
}

.custom-select .items-wrapper div:hover {
  background-color: lightgray;
}

.selectHide {
  display: none;
}

.arrow-image {
  margin-top: 15px;
}

/* label {
  margin-bottom: 5px !important;
  display: inline-block;
  font-weight: 600;
} */

.custom-select .items-wrapper{
   max-height: 150px;
   overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 0.5em;
  background-color: transparent;
}

/* Make scrollbar visible when needed */
::-webkit-scrollbar-thumb {
  background-color: #7d7d7d;
  border-radius: 8px;
}

/* Make scrollbar track visible when needed */
::-webkit-scrollbar-track {
  background-color: transparent;
}

.placeholder{
  color: #9ca3af;
}

.items-new-user{
   padding: 8px 15px;
   border-top: 1px solid #CBCBCB;
   display: flex;
   justify-content: flex-end;
   color: #56D08A;
}

.search input{
   height: auto;
    min-height: 35px;
    background-color: #fff;
    border-radius: 6px;
    /* border: 1px solid #cbcbcb; */
    color: #666;
    padding-left: 0.7em;
    /* cursor: pointer; */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    min-height: 41px !important;
}

.search input::placeholder{
   color: #9ca3af;
}

.search input:focus{
   outline: none;
   /* border: 1px solid rgb(86, 208, 138); */
}

.no-results label{
   color: #303030;
   font-weight: 300;
   font-family: 'Archivo';
}
</style>
