<template>
  <div
    v-if="showModal"
    class="modal inset-0 fixed z-10 flex justify-center items-center bg-light-2 bg-opacity-50"
  >
    <div class="modal-wrapper">
      <div class="p-6 bg-white shadow-2xl">
        <div class="flex items-center">
          <div class="text-adviz-primary mr-2">
            <img src="@/assets/img/icons/icon-edit-green.svg" alt="" />
          </div>
          <div class="font-normal modal-title">{{ action }} {{ $t('modals.addOrgModal.title') }}</div>
        </div>

        <div class="mt-2">
          <label for="inputText" class="flex gap-2">
            {{ $t('modals.addOrgModal.name') }}
            <span v-if="v$.formData.name && v$.formData.name.required" class="text-red-500">*</span>
          </label>
          <input
            type="text"
            class="border w-full rounded-lg p-2"
            :placeholder="$t('modals.addOrgModal.name')"
            v-model="formData.name"
            @blur="v$.formData.name.$touch()"
            :class="{ 'not-valid': v$.formData.name.$errors.length }"
          />
          <FieldError
            v-if="v$.formData.name.$error"
            :error="v$.formData.name.$errors[0].$message"
          />
        </div>
        <div class="mt-2">
          <TableFilterSelect
              :label="$t('modals.editUserModal.language')"
              :options="languageOptions" :placeholder="'Sélectionnez une langue'"
              :value="formData.language"
              @selection-changed="formData.language = $event.value"
              :required="true"
          />
          <FieldError v-if="v$.formData.language.$error"
                      :error="v$.formData.language.$errors[0].$message" />
        </div>
        <div v-if="isEditing">
          <div class="mt-2 flex flex-col">
            <TableFilterSelect
              :label="$t('modals.addOrgModal.selectScian')"
              :options="scianOptions"
              :value="formData.scian"
              placeholder="Choisir une option"
              @selection-changed="
                (event) => {
                  formData.scian = event?.value
                }
              "
            />
          </div>
          <div class="mt-2">
            <label for="inputText" class="flex gap-2">
              {{ $t('modals.addOrgModal.numberEmployees') }}
              <span
                v-if="v$.formData.nb_employee && v$.formData.nb_employee.required"
                class="text-red-500"
                >*</span
              >
            </label>
            <input
              type="number"
              class="border w-full rounded-lg p-2"
              :placeholder="$t('modals.addOrgModal.numberEmployees')"
              v-model="formData.nb_employee"
              min="1"
              oninput="this.value = Math.max(1, this.value)"
            />
          </div>
          <div class="mt-2">
            <label for="inputText" class="flex gap-2">
              {{ $t('modals.addOrgModal.url') }}
              <span v-if="v$.formData.url && v$.formData.url.required" class="text-red-500">*</span>
            </label>
            <input
              type="url"
              class="border w-full rounded-lg p-2"
              :placeholder="$t('modals.addOrgModal.url')"
              v-model="formData.url"
              min="0"
              @blur="v$.formData.url.$touch()"
              :class="{ 'not-valid': v$.formData.url.$errors.length }"
            />
            <FieldError
              v-if="v$.formData.url.$error"
              :error="v$.formData.url.$errors[0].$message"
            />
          </div>
          <div class="flex flex-col mt-2">
            <label for="textareaText" class="flex gap-2">
              {{ $t('modals.addOrgModal.notes') }}
              <span v-if="v$.formData.note && v$.formData.note.required" class="text-red-500"
                >*</span
              >
            </label>
            <textarea
              name=""
              id=""
              cols="45"
              rows="6"
              class="border resize-none rounded-lg p-2"
              :placeholder="$t('modals.addOrgModal.notes')"
              v-model="formData.note"
            ></textarea>
          </div>
        </div>
        <div class="flex justify-between mt-4">
          <div
            @click="closeModal"
            class="border border-red-500 text-red-500 font-semibold rounded-3xl px-3 py-2 cursor-pointer"
          >
            {{ $t('modals.addOrgModal.cancelButton') }}
          </div>
          <div
            @click="submitForm"
            class="bg-adviz-primary text-white font-semibold rounded-3xl px-3 py-2 cursor-pointer"
          >
            {{ $t('modals.addOrgModal.submitButton') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import scianOptions from '@/includes/codeSCIAN.js'
import { mapState } from 'vuex'
import OrganizationsApi from '@/apis/OrganizationsApi'
import useVuelidate from '@vuelidate/core'
import { required, helpers, url, numeric } from '@vuelidate/validators'
import FieldError from '@/views/admin/components/FieldError.vue'
import TableFilterSelect from '@/components/organisms/matrices/TableFilterSelect.vue'

export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  validations() {
    return {
      formData: {
        name: {
          required: helpers.withMessage(this.$t('validations.nameRequired'), required)
        },
        url: {
          url: helpers.withMessage(this.$t('validations.url'), this.urlValidator)
        },
        language: {
          required: helpers.withMessage(this.$t('validations.languageRequired'), required)
        },
      }
    }
  },
  components: {
    FieldError,
    TableFilterSelect
  },
  props: {
    showModal: {
      type: Boolean,
      required: true
    },
    selectedOrganization: {
      type: Object,
      required: false,
      default: null
    },
    clientOrgs: {
      type: Array,
      required: false,
      default: () => []
    },
    organizations: {
      type: Array,
      required: false,
      default: () => []
    },
    users: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      scianOptions: scianOptions.map((x) => {
        return {
          name: `${x.code} - ${this.$t(x.industryFr)}`,
          value: x.code
        }
      }),
      formData: {
        name: '',
        language: 'fr',
        scian: null,
        nb_employee: null,
        url: null,
        note: null
      },
      languageOptions: [
        {
          name: 'English',
          value: 'en'
        },
        {
          name: 'French',
          value: 'fr'
        }
      ],
    }
  },
  computed: {
    // Get auth
    ...mapState(['auth']),
    action() {
      return this.selectedOrganization !== null
        ? this.$t('modals.addOrgModal.action.edit')
        : this.$t('modals.addOrgModal.action.add')
    },
    isEditing() {
      return this.selectedOrganization !== null
    },
    urlValidator() {
      return (value) => {
        // if url field is empty, don't validate
        if (!value) {
          return true
        }

        // Regular expression for matching URLs without requiring http:// or https://
        const urlPattern = /^(https?:\/\/)?([\w.-]+\.[a-z]{2,})(\/\S*)?$/i

        // Test the value against the pattern
        return urlPattern.test(value)
      }
    }
  },
  watch: {
    showModal: {
      handler: function (val) {
        if (val) {
          if (this.selectedOrganization) {
            this.formData = {
              name: this.selectedOrganization.name,
              language: this.selectedOrganization.language,
              scian: this.selectedOrganization.scian,
              nb_employee: this.selectedOrganization.nb_employee,
              url: this.selectedOrganization.url,
              note: this.selectedOrganization.note
            }
          } else {
            this.formData = {
              name: '',
              language: 'fr',
              scian: null,
              nb_employee: null,
              url: null,
              note: null
            }
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
    },
    async submitForm() {
      const formData = {
        name: this.formData.name,
        language: this.formData.language,
        scian: this.formData.scian,
        nb_employee: this.formData.nb_employee,
        url: this.formData.url,
        note: this.formData.note,
        parent_id: this.auth.currentUser.organization_id
      }
      if (this.selectedOrganization) {
        formData.id = this.selectedOrganization.id

        this.v$.formData.$touch()

        if (this.v$.formData.$invalid) {
          return
        } else {
          // Edit

          // trim values
          for (const key of Object.keys(formData)) {
            if (typeof formData[key] === 'string') {
              formData[key] = formData[key].trim()
            }
          }

          await OrganizationsApi()
            .editOrganization(this.selectedOrganization.id, formData)
            .then((response) => {
              this.closeModal()
              this.$store.dispatch('SET_ORGANIZATIONS')

              const orgName = formData.name
              const orgId = response.data.data.id
              const orgLink = `/clients/${orgId}`
              const orgMsg = this.$t('modals.addOrgModal.org')
              const orgModified = this.$t('modals.addOrgModal.modified')
              const message = `${orgMsg} <a class="underline" href="${orgLink}">${orgName}</a> ${orgModified}.`

              this.$toasts.success(message)
            })
            .catch((error) => {
              console.log('error', error)
              this.$toasts.error(this.$t('modals.addOrgModal.editError'))
            })
        }
      } else {
        // Create
        this.v$.formData.$touch()

        if (this.v$.formData.$invalid) {
          return
        } else {
          // trim values
          for (const key of Object.keys(formData)) {
            if (typeof formData[key] === 'string') {
              formData[key] = formData[key].trim()
            }
          }

          await OrganizationsApi()
            .createOrganization(formData)
            .then((response) => {
              this.closeModal()
              this.$store.dispatch('SET_ORGANIZATIONS')

              const orgName = formData.name
              const orgId = response.data.data.id
              const orgLink = `/clients/${orgId}`
              const orgMsg = this.$t('modals.addOrgModal.org')
              const orgCreated = this.$t('modals.addOrgModal.created')
              const message = `${orgMsg} <a class="underline" href="${orgLink}">${orgName}</a> ${orgCreated} .`

              this.$toasts.success(message)
            })
            .catch((error) => {
              console.log('error', error)
              this.$toasts.error(this.$t('modals.addOrgModal.addError'))
            })
        }
      }
    }
  }
}
</script>

<style></style>

<style scoped>
.modal {
  background-color: #14141482 !important;
  z-index: 10001;
}

.modal-wrapper {
  @apply flex
      justify-center
      items-center
      fixed
      inset-0
      z-50;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  padding: 20px 0;
}

@media screen and (max-height: 800px) {
  .modal-wrapper {
    @apply items-start; /* Center-align the modal */
  }
}

.modal .bg-white {
  border-radius: 8px;
  max-width: 90%;
  width: 565px;
}

.font-normal.modal-title {
  font-family: "Archivo";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #303030;
  margin-bottom: 8px;
}
</style>
