import { findKey, findValue } from 'lodash';

export const QuestionnaireTypes = {
    'pos-dg' : 1,
    'pos-dg-2' : 2,
    'pos-staff' : 3,
    'pos-consumer' : 4,
    's5' : 5,
    'd3' : 6,
    'pos-staff-2' : 7,
    'pos-consumer-2' : 8,
    'pos-consumer-3' : 9,
    'pos-consumer-4' : 10,
    'pos-distributor' : 11,
    'pos-retailer' : 12,
    'pos-supplier' : 13,
    'odase': 14,
    'odase-calm' : 14,
    'odase-storm' : 15,
    'odase-express': 16,
    'values-personal': 18,
}

export const QuestionnaireReportSlugs = {
    'odase': 'odase',
    'odase-calm': 'odase',
    'odase-storm': 'odase',
    'odase-express': 'odase',
    'values-personal': 'values'
}

// Get the questionnaire type from the value
export const getQuestionnaireTypeByID = (value) => {
    return findKey(QuestionnaireTypes, (o) => o === value);
}

export const getQuestionnaireReportSlugByID = (value) => {
    const key = findKey(QuestionnaireTypes, (o) => o === value);
    return QuestionnaireReportSlugs[key];
}

