<template>
  <div class="flex items-center mb-2 gap-3" v-if="label">
    <label for="" class="flex gap-2">
      {{ label }}
      <span v-if="required" class="text-red-500"> *</span>
    </label>
    <Tooltip
      v-if="showTooltip"
      text="Hover me"
      :tooltipText="tooltipText"
      position="right"
      img="icons/tooltip-icon.svg"
    />
  </div>
  <div class="custom-select" :class="{'cursor-not-allowed opacity-75': disabled, [customClass]: true }" :tabindex="tabindex" v-click-outside="closeDropdown">
    <div class="selected" :class="{ 'open': open }" @click="toggleDropdown">
      <div
        class="
          placeholder
          inline-block
          text-gray-600
          font-medium
          mr-auto
          leading-5
          w-10/12
          py-3
        "
        v-if="selected == undefined || selected == null || showAll"
      >
        {{ $t('filters.tableFilterSelectPlaceholder') }}
      </div>
      <div
        class="
          inline-block
          text-gray-600
          font-medium
          mr-auto
          leading-5
          w-10/12
          py-3
        "
        v-else
      >
        {{ selected?.name }}
      </div>
      <img
        src="@/assets/img/icons/icon-down-arrow.svg"
        alt=""
        class="inline-block absolute right-0 mr-4 arrow-image"
      />
    </div>
    <div class="items font-archivo" :class="{ selectHide: !open }">
      <div class="items-wrapper divide-y" v-if="options && options.length !== 0">
        <div
          v-for="(option, i) of options"
          :key="i"
          @click="
            selected = option;
            open = false;
            $emit('selection-changed', option);
          "
          class="role-item border-l-3 border-l-transparent group hover:border-l-adviz-primary py-3 leading-5 transition-all"
          :class="{'!border-l-adviz-primary': selected?.value === option.value}"
        >
        <!-- ITEM TITLE -->
        <p class="flex gap-1 mb-3 group-hover:text-adviz-primary transition-all" :class="{ 'text-adviz-primary': selected?.value === option.value }">
          <span class="font-semibold">{{ option.name }}</span>
          <span>({{getRoleSubTitle(option.value) }})</span>
        </p>
        <!-- BENEFITS -->
        <ul class="benefits" v-if="getRoleBenefits(option.value) && getRoleBenefits(option.value)?.length > 0">
          <li
            v-for="(benefit, bIdx) in getRoleBenefits(option.value)"
            :key="bIdx"
            class="benefits-item"
            :class="benefit.value"
          >
          <i class="icon-role-yes" v-if="benefit.value == 'yes'"></i>
          <i class="icon-role-no" v-else></i>
            {{ getRoleSingleBenefit(option.value, bIdx) }}
          </li>
        </ul>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import { RoleTypes, getRoleTypeByID } from "@/helpers/roleTypes";

export default {
    props: {
        label: {
            type: String,
            default: "",
            required: false,
        },
        options: {
            type: Array,
            required: true,
        },
        placeholder: {
            type: String,
            default: "Tous",
        },
        value: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        selectedByDefault: {
            type: Object,
            default: null,
        },
        class: {
          type: String,
          default: null,
        },
        required: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["selection-changed"],
    data() {
        return {
          open: false,
          selected: null,
        };
    },
  methods: {
    setSelected() {
      if (this.options === undefined || this.options === null) {
        return null;
      }

      this.selected = this.options?.find((option) => option.value === undefined
        ? option.name == this.value
        : option.value == this.value);
    },
    toggleDropdown() {
      if (!this.disabled) {
        this.open = !this.open;
      }
    },
    closeDropdown() {
      this.open = false;
    },
    getRoleSubTitle(id) {
      // I need to do this way, as I wasn't able to translate in roleTypes.js directly
      // RoleTypes[getRoleTypeByID(id)]?.title gives me 'ClientConsumer.title'.
      // So the output: 'role.details.ClientConsumer.title'. This will be translated
      return this.$t(`roles.details.${RoleTypes[getRoleTypeByID(id)]?.title}`);
    },
    getRoleBenefits(id) {
      return RoleTypes[getRoleTypeByID(id)]?.benefits
    },
    getRoleSingleBenefit(id, index) {
      // I need to do this way, as I wasn't able to translate in roleTypes.js directly
      // RoleTypes[getRoleTypeByID(id)]?.benefits[index].label gives me 'ClientConsumer.benefit1'
      // So the output: 'role.details.ClientConsumer.benefit1'. This will be translated
      return this.$t(`roles.details.${RoleTypes[getRoleTypeByID(id)]?.benefits[index].label}`);
    }
  },
  computed: {
    customClass() {
      return this.class;
    },
  },
  watch: {
    value(newValue) {
        this.setSelected();
    },
    selectedByDefault(newValue) {
      this.selected = newValue;
      this.$emit('selection-changed', this.selected);
    },
    options(newValue) {
      this.setSelected();
    },
  },
  mounted() {
      this.setSelected();
      if (this.selectedByDefault) {
        this.selected = this.selectedByDefault;
        this.$emit('selection-changed', this.selected);
      }
  },
};
</script>

<style scoped>
.default {
  color: #595959;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
}

.custom-select {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  height: auto;
  min-height: 47px;
}

.custom-select .selected {
  height: auto;
  min-height: 35px;
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #E5E7EB;
  color: #666;
  padding-left: 0.7em;
  /* cursor: pointer; */
  user-select: none;
  min-height: 41px !important;
}

.custom-select .selected.open {
  border: 1px solid rgb(86, 208, 138);
  border-radius: 6px 6px 6px 6px;
  margin-bottom: 8px;
}

.custom-select.not-valid .selected{
  border-color: #f57f6c;
}

.custom-select .selected > div{
   text-overflow: ellipsis;
   overflow: hidden;
   white-space: nowrap;
   padding-bottom: 0;
}
/*
  .custom-select .selected:after {
    position: absolute;
    content: "";
    top: 22px;
    right: 1em;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-color: #fff transparent transparent transparent;
  } */

.custom-select .items {
  border-radius: 6px 6px 6px 6px;
  overflow: hidden;
  border-right: 1px solid lightgray;
  border-left: 1px solid lightgray;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  position: absolute;
  background-color: #fff;
  left: 0;
  right: 0;
  z-index: 9;
  line-height: 24px;
}

.filter-container .custom-select .items{
  width: 120%;
}

.custom-select .items-wrapper div {
  color: #595959;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
}


.selectHide {
  display: none;
}

.arrow-image {
  margin-top: 15px;
}

/* label {
  margin-bottom: 5px !important;
  display: inline-block;
  font-weight: 600;
} */

.custom-select .items-wrapper{
   max-height: 350px;
   overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 0.5em;
  background-color: transparent;
}

/* Make scrollbar visible when needed */
::-webkit-scrollbar-thumb {
  background-color: #7d7d7d;
  border-radius: 8px;
}

/* Make scrollbar track visible when needed */
::-webkit-scrollbar-track {
  background-color: transparent;
}

.placeholder{
  color: #9ca3af;
}

.benefits {
  list-style: none;
  padding-left: 16px;
}

.benefits-item{
  font-size: 12px;
  line-height: 16px;
}
</style>
