<template>
  <div class="global-create relative" id="globalCreate" v-if="initialized && filteredOptions.length" v-title="tooltip" v-click-outside="closeMenu">
    <DropdownButton
      name="Actions"
      @click.stop="toggleMenu"
      :class="{ ' opacity-50 cursor-not-allowed': disabled }"
      :active="menuVisible"
    />
    <TooltipMenu
      :open="menuVisible"
      :options="options"
      class="hidden lg:block"
      @click="handleMenuClick"
    />
    <TooltipMenu
      position="right"
      :open="menuVisible"
      :options="options"
      class="lg:hidden"
      @click="handleMenuClick"
    />
  </div>

  <!-- Modals -->
  <Teleport to="body">
    <CreateProjectModal
      :showModal="showCreateProjecModal"
      :projectIdx="null"
      :projects="projects"
      :organizationID="auth.currentUser.organization_id"
      :selectedOrg="orgId"
      :organizations="organizations"
      :productBalances="balancesByProducts"
      @closeModal="closeModal('CreateProjectModal')"
      @addNewOrg="openModal('AddOrgModal')"
      :class="{ 'opacity-0': !isModalVisible('CreateProjectModal') }"
    />

    <AssignQuestionnaireModal
      :projects="projects"
      :users="users"
      :selectedUser="null"
      :selectedProject="projectId ? projects.filter(proj => proj.id == projectId)[0]?.id : null"
      :showModal="showAssignQuestionnaireModal"
      :balancesByProducts="balancesByProducts"
      @closeModal="closeModal('AssignQuestionnaireModal')"
      @createNewUser="openModal('AddUserModal', 'Client')"
      :class="{ 'opacity-0': !isModalVisible('AssignQuestionnaireModal') }"
    />

    <AddUserModal
    :showModal="showAddUserModal"
    :users="users"
    :organizationID="auth.currentUser.organization_id"
    :selectedOrganization="orgId"
    :organizations="organizations"
    :projectIdx="null"
    :typeUserToAdd="typeUserToAdd"
    :addNewUserForce="true"
    @closeModal="closeModal('AddUserModal')"
    @addNewTag="openModal('AddUserTagModal')"
    @addNewOrg="openModal('AddOrgModal')"
    :class="{ 'opacity-0': !isModalVisible('AddUserModal') }"
  />

  <AddOrgModal
    v-if="showAddOrgModal"
    :showModal="showAddOrgModal"
    :users="users"
    :selectedOrganization="selectedOrganization"
    :organizations="organizations"
    :clientOrgs="clientOrganizations"
    @closeModal="closeModal('AddOrgModal')"
    :class="{ 'opacity-0': !isModalVisible('AddOrgModal') }"
  />

  <AddUserTagModal
      :showModal="showAddUserTagModal"
      :organizationID="auth.currentUser.organization_id"
      @closeModal="closeModal('AddUserTagModal', 'AddUserModal')"
      :class="{ 'opacity-0': !isModalVisible('AddUserTagModal') }"
    />
  </Teleport>


</template>

<script>
import DropdownButton from '@/components/molecules/form/DropdownButton.vue';
import TooltipMenu from "@/views/admin/components/TooltipMenu.vue";
import { mapState } from "vuex";
// modals
import CreateProjectModal from "@/views/admin/modals/EditCreateProjectModal.vue";
import AssignQuestionnaireModal from "@/views/admin/modals/AssignQuestionnaireModal.vue";
import AddUserModal from "@/views/admin/modals/AddUserModal.vue";
import AddOrgModal from "@/views/admin/modals/AddOrgModal.vue";
import AddUserTagModal from "@/views/admin/modals/AddUserTagModal.vue";

import formatDate from "@/helpers/formatDate";




export default {
   name: 'GlobalCreateButton',
   components: {
      DropdownButton,
      CreateProjectModal,
      AssignQuestionnaireModal,
      AddUserModal,
      AddOrgModal,
      AddUserTagModal,
      TooltipMenu,
   },
   props: {
      disabled: {
         type: Boolean,
         default: false,
      },
      tooltip: {
         type: String,
         default: '',
      },
   },
  inject: ['orgId', 'projectId'],
   data() {
      return {
         initialized: false,
         options: [],
         menuVisible: false,
         showCreateProjecModal: false,
         showAddUserModal: false,
         showAssignQuestionnaireModal: false,
         showAddOrgModal: false,
         typeUserToAdd: "?",
         selectedOrganization: null,
         showAddUserTagModal: false,
         openedModals: [],
      }
   },
   computed: {
      ...mapState(["auth", "users", "organizations", "projects", "balancesByProducts"]),
      clientOrganizations() {
         return this.organizations.filter((org) => org.parent_id !== null).map(
            (org) => {
               return {
                  id: org.id,
                  name: org.name,
                  lastProject: org.projects[0]?.name,
                  lastProjectDate: this.formatDate(org.projects[0]?.created_at),
               };
            }
         );
      },
      organization() {
         return this.organizations.find((org) => org.id === this.auth.currentUser.organization_id);
      },
      isSimplified() {
         return this.organization?.simplified;
      },
      isSuperAdmin() {
       return this.auth.currentUser.roles[0].name === 'Super Admin Tech'
      },
      isStaff() {
         return this.auth.currentUser.roles[0].name === "Partner-staff";
      },
      addProjectButtonEnabled() {
         if (import.meta.env.MODE === 'play') {
            return false;
         }
         if (this.auth.currentUser.roles[0].name === 'Partner-staff') {
            return false;
         }
         return this.auth.currentUser.organization_id !== null &&
            this.organizations.length > 0
      },
      filteredOptions() {
         return this.options.filter((option) => option.visibility);
      },
      isModalVisible() {
         return (modal) => {
            if (this.openedModals.length === 0) return false;
            return this.openedModals.includes(modal) && this.openedModals[this.openedModals.length - 1] === modal;
         }
      },
   },
   methods: {
      init() {
         this.createMenuOptions();
         this.initialized = true;
      },
      toggleMenu() {
         if (this.disabled) return;
         this.menuVisible = !this.menuVisible;
      },
      closeMenu() {
         if (!this.menuVisible) return;
         this.menuVisible = false;
       // console.log('closeMenu')
      },
      handleMenuClick(option) {
        // console.log('clicked')
         if (option && option.onClick) {
            option.onClick();
         }
      },
      createMenuOptions() {
         this.options = [
            {
               label: this.$t("header.globalCreateButton.addOrgModal"),
               visibility: this.isSimplified ? false : (!this.isStaff),
               onClick: () => this.openModal('AddOrgModal'),
            },
            {
               label: this.$t("header.globalCreateButton.createProjectModal"),
               visibility: this.isSimplified ? false : this.addProjectButtonEnabled,
               onClick: () => this.openModal('CreateProjectModal'),
            },
            {
               label: this.$t("header.globalCreateButton.addUserModal_client"),
               visibility: true,
               onClick: () => this.openModal('AddUserModal', 'Client'),
            },
            {
               label: this.$t("header.globalCreateButton.assignQuestionnaireModal"),
               visibility: true,
               onClick: () => this.openModal('AssignQuestionnaireModal'),
            },
            {
               label: this.$t("header.globalCreateButton.addUserModal_partner"),
               visibility: !this.isStaff,
               onClick: () => this.openModal('AddUserModal', 'Partner'),
            },
            {
               label: "SU - Create Partner",
               visibility: this.isSuperAdmin,
               onClick: () => this.$router.push({ name: 'OrganizationCreate' }) && this.closeMenu(),
            },
            {
               label: "SU - Create Client For Partner",
               visibility: this.isSuperAdmin,
               onClick: () => this.$router.push({ name: 'OrganizationCreateClient' }) && this.closeMenu(),
            },
         ];
      },
      openModal(type, typeUserToAdd) {
         // Add the opened modal to the stack
         this.openedModals.push(type);
         this.closeMenu();

         switch (type) {
            case "AddUserModal":
               this.typeUserToAdd = typeUserToAdd
               this.showAddUserModal = true;
               // this.showAssignQuestionnaireModal = false;
               break;
            case "CreateProjectModal":
               this.showCreateProjecModal = true;
               break;
            case "AssignQuestionnaireModal":
               this.showAssignQuestionnaireModal = true;
               break;
            case "AddOrgModal":
               this.showAddOrgModal = true;
               break;
            case "AddUserTagModal":
               this.showAddUserTagModal = true;
               break;
         }
      },
      closeModal(type) {

         // Remove the closed modal from the stack
         const index = this.openedModals.indexOf(type);
         if (index !== -1) {
            this.openedModals.splice(index, 1);
         }

         switch (type) {
            case "AddUserModal":
               this.typeUserToAdd = "?";
               this.showAddUserModal = false;
               break;
            case "CreateProjectModal":
               this.showCreateProjecModal = false;
               break;
            case "AssignQuestionnaireModal":
               this.showAssignQuestionnaireModal = false;
               break;
            case "AddOrgModal":
               this.showAddOrgModal = false;
               break;
            case "AddUserTagModal":
               this.showAddUserTagModal = false;
               break;
         }
      },
      formatDate(value) {
         if (value) {
            return formatDate(value);
         }
      },

   },
   watch: {
      addProjectButtonEnabled() {
         this.createMenuOptions();
      },
      menuVisible (val) {
         if (val) {
            this.$emit('open');
         }
      },
   },
   mounted() {
      this.init();
   },
}
</script>

<style scoped>
.global-create__menu{
   position: absolute;
   top: 40px;
   left: 0;
   border-radius: 8px;
   border: 1px solid #56D08A;
   background: #FFF;
   min-width: 220px;
}

@media (min-width: 1536px){
   .global-create__menu{
      top: 50px;
   }
}

.global-create__menu li{
   font-family: Archivo;
   font-size: 12px;
   font-style: normal;
   font-weight: 500;
   line-height: normal;
   padding: 8px 24px;
}

.global-create__menu li:first-child{
   padding-top: 12px
}

.global-create__menu li:last-child{
   padding-bottom: 12px
}

.global-create__menu li:hover{
   background: #56d08b3a;
   cursor: pointer;
}
.global-create__menu li:not(:last-child){
   border-bottom: 1px solid #F2F2F2;
}
</style>
