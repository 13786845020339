<script setup>
import { computed, reactive, watch } from "vue";
import Edit from "@/components/atoms/icons/Edit.vue";
import Close from "@/components/atoms/icons/Close.vue";

// translation
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const props = defineProps({
   context: Object,
})

// STATE
const state = reactive({
   entity: props.context.node.value ?? [],
   addNew: props.context.open ?? false,
})

// COMPUTED
const max = computed(() => {
   return props.context.max ?? 1; // default max amount of items
})

const newItemsAllowed = computed(() => {
   return state.entity.length < max.value;
})

const hasDescription = computed(() => {
   return props.context.descriptionLabel || props.context.descriptionPlaceholder
})

// METHODS
function showAddForm() {
   state.addNew = true;
}

function closeAddForm() {
   state.addNew = false;
}

function addNew(item) {
   if (!item.name || !item.name.trim().length) {
      return;
   }

   item.edit = false;
   state.entity.push(item)
   state.addNew = false;

   syncEntityState()
}

function toggleEditEntity(index) {
   state.entity[index].edit = !state.entity[index].edit;
}

function saveEditEntity(item, index) {
   state.entity[index] = item;
   state.entity[index].edit = false;

   syncEntityState()
}

function deleteEntity(index) {
   state.entity.splice(index, 1)

   syncEntityState()
}

function syncEntityState() {
  // TODO: edit toggle is not relevant to store - maybe better to handle that in a separate array
   props.context.node.input(state.entity)
}

</script>

<template>
   <div v-if="props.context.question" class="text-xl py-4">
      {{ props.context.question }}
   </div>
   <div :id="props.context.id" class="entity flex flex-col gap-5 p-5">
      <!-- LIST OF EXISTING ENTITIES  -->
      <div class="entity-items flex flex-col gap-3" v-if="state.entity.length > 0">
         <div class="entity-item px-0 py-0 bg-white relative" :class="{'w-fit pr-20 !bg-adviz-bg rounded px-4 py-2': !hasDescription && !item.edit}" v-for="(item, index) in state.entity" :key="index">
            <!-- normal mode -->
            <template v-if="!item.edit">
               <div class="entity-item-name font-semibold">{{ item.name }}</div>
               <div class="entity-item-description text-sm" v-if="hasDescription">{{ item.description }}</div>
               <Edit fill="#56D08A" class="absolute right-10 top-2 w-4 cursor-pointer" @click="toggleEditEntity(index)"/>
               <Close fill="#56D08A" class="absolute right-3 top-2 w-4 cursor-pointer" @click="deleteEntity(index)" />
            </template>

            <!-- edit mode -->
            <template v-else>
               <FormKit
                  type="form"
                  id="editentity"
                  :actions="false"
                  @submit="(form) => saveEditEntity(form, index)"
                  :value="{ name: item.name, description: item.description }"
                  :classes="{
                     'form': 'bg-white rounded',
                     'messages': '!p-0 !pt-2'
                  }"
               >
               <FormKit
                  type="text"
                  :label="props.context.nameLabel ?? 'Name'"
                  name="name"
                  :placeholder="props.context.namePlaceholder ?? 'Name of your entity'"
                  v-model="name"
                  validation="required"
                  :validation-messages= "{
                     required: t('formkit.required'),
                  }"
               />
               <FormKit
                  v-if="hasDescription"
                  type="textarea"
                  :label="props.context.descriptionLabel ?? 'Description'"
                  :placeholder="props.context.descriptionPlaceholder ?? 'Description of your entity'"
                  name="description"
                  v-model="description"
               />

               <div class="flex gap-3">
                  <FormKit
                     type="submit"
                     :label="props.context.saveLabel ?? t('formkit.save')"
                     prefix-icon="check"
                     :classes="{
                        'wrapper': '!p-0',
                        'outer': '!mb-0'
                     }"
                  />
                  </div>
               </FormKit>
            </template>
         </div>
      </div>

      <!-- BUTTON TO ADD NEW ENTITY -->
      <FormKit
         type="button"
         :label="props.context.buttonLabel ?? 'Add'"
         @click="showAddForm"
         v-if="!state.addNew && !props.context.readonly"
         :disabled="!newItemsAllowed"
         :help="!newItemsAllowed ? `${t('formkit.limitReached')} (${t('formkit.max')} ${max})` : ''"
         :classes="{
            'input': !newItemsAllowed ?'!cursor-not-allowed' : '',
            'outer': '!mb-0 flex-col items-center justify-center',
            'wrapper': 'flex items-center justify-center',
            'help': 'text-center'
         }"
      />

      <!-- FORM TO ADD NEW ENTITY -->
      <div v-if="state.addNew && !props.context.readonly">
         <FormKit
            type="form"
            id="addNew"
            :actions="false"
            @submit="addNew"
            #default="{ value }"
            :classes="{
               'form': 'mb-4',
               'messages': '!p-0 !pt-2'
            }"
            :incomplete-message="t('formkit.fillAllFields')"
         >
         <FormKit
            type="text"
            :label="props.context.nameLabel ?? 'Name'"
            name="name"
            :placeholder="props.context.namePlaceholder ?? 'Name of your entity'"
            v-model="value.name"
            validation="required"
            :validation-messages= "{
               required: t('formkit.required')
            }"
         />
         <FormKit
            v-if="hasDescription"
            type="textarea"
            :label="props.context.descriptionLabel ?? 'Description'"
            :placeholder="props.context.descriptionPlaceholder ?? 'Description of your entity'"
            name="description"
            v-model="value.description"
         />

         <div class="flex gap-3">
            <FormKit
               type="submit"
               :label="props.context.saveLabel ?? t('formkit.save')"
               prefix-icon="check"
               :classes="{
                  'wrapper': '!p-0',
                  'outer': '!mb-0'
               }"
            />

            <FormKit
               type="button"
               :label="props.context.cancelLabel ?? t('formkit.cancel')"
               prefix-icon="close"
               @click="closeAddForm"
               :classes="{
                  'wrapper': '!p-0',
                  'input': '!bg-transparent !text-gray-500',
                  'outer': '!mb-0'
               }"
            />
         </div>

         </FormKit>
      </div>

   </div>
</template>
