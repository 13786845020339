<template>
   <div class="flex items-center mb-2 gap-3">
      <label class="flex gap-2">
         {{ label }}
         <span v-if="required" class="text-red-500"> *</span>
      </label>
      <Tooltip
         v-if="showTooltip"
         text="Hover me"
         :tooltipText="tooltipText"
         position="right"
         img="icons/tooltip-icon.svg"
      />
   </div>
   <div class="custom-select" :class="{ 'cursor-not-allowed opacity-75': disabled, [customClass]: true }" :tabindex="tabindex" v-click-outside="closeDropdown">
      <div class="selected" :class="{ 'open': open }" @click="toggleDropdown">
         <div class="inline-block text-gray-600 font-medium mr-auto leading-5 w-10/12 py-3" :class="{ 'cursor-not-allowed opacity-75': disabled, 'placeholder': selected.length === 0 }">
            {{ selectedItemsDisplay }}
         </div>
         <img v-if="!disabled" src="@/assets/img/icons/icon-down-arrow.svg" alt=""
            class="inline-block absolute right-0 mr-4 arrow-image" />
      </div>
      <div class="items font-archivo" :class="{ selectHide: !open }">
         <form id="search" class="search w-full px-2 my-2 flex gap-2">
            <img src="@/assets/img/icons/search-icon.svg" alt="icon">
            <input name="query" v-model="searchQuery" :placeholder="$t(searchText)" class="w-full" ref="search">
         </form>

         <div class="items-wrapper" v-if="filteredOptions.length !== 0">
            <div v-for="(option, i) of filteredOptions" :key="i" class="leading-5 checkbox" :class="{'disabled cursor-not-allowed opacity-50': option.disabled?.value}">
               <input type="checkbox" class="form-checkbox h-5 w-5 text-green-600" :data-checked="isSelected(option)" :checked="isSelected(option)" :id="`checkbox-${i}`" :class="{ 'disabled !cursor-not-allowed opacity-50': option.disabled?.value }" />
               <label class="checkbox-label flex items-center cursor-pointer py-2" :for="`checkbox-${i}`" @click.prevent="toggleSelection(option, i)" :class="{ 'disabled !cursor-not-allowed opacity-50': option.disabled?.value }">
                  {{ option.name }}
                  <template v-if="option.role">
                     {{ ` - ${option.role}` }}
                  </template>
                  <template v-if="option.disabled && option.disabled?.value">
                     ({{ option.disabled.text }})
                  </template>
               </label>
               <div v-if="option.desc" class="mt-1">{{ option.desc }}</div>
               <template v-if="option.subElements && selected.length == 0">
                  <div v-for="(subElement, j) of option.subElements" :key="j" class="ml-2 my-0">
                     <span class="flex items-center cursor-default text-xs text-gray-400">
                        <template v-if="subElement && j">
                           {{ `${j}: ${subElement}` }}
                        </template>
                     </span>
                  </div>
               </template>
            </div>
         </div>
         <div v-else class="no-results px-12">
            <label> {{$t(noSearchResults)}} </label>
         </div>
         <div
            class="items-new-user cursor-pointer"
            @click.stop="createNew"
            :disabled="lockAddNewTag"
            :class="{'cursor-not-allowed opacity-30' : lockAddNewTag}"
            :title="lockAddNewTag ? `${this.$t('filters.tableFilterSelectMulti.10tagslimit')}` : ''"
         >
            <span v-if="!createNewInstantVisible">{{ $t(addNewText) }}</span>
            <span v-else class="flex gap-3 items-center">
               <input
                  type="text"
                  name="name"
                  id="id"
                  v-model="newInstantValue"
                  class="border w-full rounded-lg p-2 text-dark focus:outline-none"
                  placeholder="Name"
                  ref="newTagInput"
                  @keyup.enter="addValueInstantly"
               >
               <a @click="addValueInstantly">{{ $t('filters.tableFilterSelectMulti.add') }}</a>
            </span>
         </div>
      </div>
   </div>
</template>

<script>
import Tooltip from '@/components/molecules/Tooltip.vue';
import { nextTick } from 'vue';

export default {
   props: {
      label: {
         type: String,
         default: "",
         required: false,
      },
      options: {
         type: Array,
         required: true,
      },
      tabindex: {
         type: Number,
         required: false,
         default: 0,
      },
      value: {
         type: String,
         default: null,
      },
      showAll: {
         type: Boolean,
         default: false,
      },
      disabled: {
         type: Boolean,
         default: false,
      },
      class: {
         type: String,
         default: null,
      },
      searchText: {
         type: String,
         default: "filters.tableFilterSelectMulti.searchText",
      },
      noSearchResults: {
         type: String,
         default: "filters.tableFilterSelectMulti.noSearchResults",
      },
      addNewText: {
         type: String,
         default: "filters.tableFilterSelectMulti.addNewText",
      },
      lockAddNewTag: {
         type: Boolean,
         default: false,
      },
      showTooltip: {
         type: Boolean,
         default: false,
      },
      tooltipText: {
         type: String,
         default: "Your tooltip text",
         required: false,
      },
      required: {
         type: Boolean,
         default: false,
      },
      createNewInstantMode: {
         type: Boolean,
         default: false,
      },
      selectedByDefault: {
         type: Object,
         default: null,
      },
   },
   components: { Tooltip },
   emits: ["selection-changed", "clear-selection"],
   data() {
      return {
         open: false,
         selected: [],
         searchQuery: "",
         createNewInstantVisible: false,
         newInstantValue: '',
      };
   },
   methods: {
      // closeIfClickedOutside(e) {
      //    if (!this.open) return;
      //    if (!this.$el.parentNode.lastElementChild.contains(e.target)) {
      //       this.open = false;
      //    }
      // },
      toggleDropdown() {
         if (!this.disabled) {
            this.open = !this.open;
            this.createNewInstantVisible = false;

            if (this.open) {
               this.$nextTick(() => {
                  this.$refs.search.focus();
               });
            }
         }
      },
      closeDropdown() {
         this.open = false;

         // clear search query
         this.searchQuery = "";
      },
      toggleSelection(option, i) {
         if (option.disabled && option.disabled?.value) return;
         // Toggle the selection of an option
         const index = this.selected.findIndex((item) => item.value === option.value);
         if (index === -1) {
            this.selected.push(option);
         } else {
            this.selected.splice(index, 1);
         }
         this.$emit("selection-changed", this.selected);
         document.getElementById(`checkbox-${i}`).checked = true;
         // console.log(document.getElementById(`checkbox-${i}`));
      },
      isSelected(option) {
         return this.selected.some((item) => item.value === option.value);
      },
      setSelected() {
         // this.selected = this.options.filter((option) =>
         //    this.value ? this.value.includes(option.value) : false
         // );
         if (this.value) {
            this.selected = this.value;
         }
      },
      clearSelection() {
         this.selected = [];
         this.$emit("clear-selection");
      },
      createNew() {
         if (this.lockAddNewTag) return;

         if (this.createNewInstantMode) {
            this.createNewInstantVisible = true;
            this.$nextTick(() => {
               this.$refs.newTagInput.focus();
            });
         } else {
            this.$emit("createNew");
            this.toggleDropdown();
         }
      },
      addValueInstantly() {
         if (this.newInstantValue) {
            this.$emit("createNew", this.newInstantValue);
            this.newInstantValue = '';
            this.createNewInstantVisible = false;
         }
      },
   },
   computed: {
      filteredOptions() {
         if (!this.options) {
            return [];
         }

         const selectedIds = this.selected.map(selectedItem => selectedItem.value);

         const selectedItems = this.selected.filter((selectedItem) =>
            selectedItem.name?.toLowerCase().includes(this.searchQuery.toLowerCase())
         );

         const disabledItems = this.options.filter((option) => option.disabled?.value);

         const remainingOptions = this.options.filter((option) => {
            return (
               option.name?.toLowerCase().includes(this.searchQuery.toLowerCase()) &&
               !selectedIds.includes(option.value) &&
               !option.disabled?.value
            );
         });

         return selectedItems.concat(remainingOptions, disabledItems);
      },
      selectedItemsDisplay() {
         if (this.selected.length === 0) {
            return this.$t("modals.addUserModal.select");
         } else if (this.selected.length < 3) {
            return this.selected.map((item) => item.name).join(", ");
         } else {
            return `${this.selected[0].name}, ${this.selected[1].name} + ${this.selected.length - 2} de plus`;
         }
      },
      customClass() {
         return this.class;
      },
   },
   watch: {
      value(newValue) {
         this.setSelected();
      },
      options(newValue) {
         // this.clearSelection();
      },
      selectedByDefault(newValue) {
         if (newValue && newValue !== null && !this.selected.some(item => item.value === newValue.value)) {
            this.selected.push(newValue);
         } else if (newValue === null || !newValue) {
            this.clearSelection();
         }
      },
   },
   mounted() {
      this.setSelected();
      // document.addEventListener('click', this.closeIfClickedOutside)

      if (this.selectedByDefault) {
         if (!this.selected.some(item => item.value === this.selectedByDefault.value)) {
            this.selected.push(this.selectedByDefault);
         }
      }
   },
   // beforeUnmount() {
   //    document.removeEventListener('click', this.closeIfClickedOutside)
   // },
};
</script>

<style scoped>
.default {
  color: #595959;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
}

.custom-select {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  height: auto;
  min-height: 47px;
}

.custom-select .selected {
  height: auto;
  min-height: 35px;
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #cbcbcb;
  color: #666;
  padding-left: 0.7em;
  cursor: pointer;
  user-select: none;
  min-height: 41px !important;
}

.custom-select .selected.open {
  border: 1px solid rgb(86, 208, 138);
  border-radius: 6px 6px 6px 6px;
  margin-bottom: 8px;
}

.custom-select.not-valid .selected{
  border-color: #f57f6c;
}

.custom-select .items {
  border-radius: 6px 6px 6px 6px;
  overflow: hidden;
  border-right: 1px solid lightgray;
  border-left: 1px solid lightgray;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  position: absolute;
  background-color: #fff;
  left: 0;
  right: 0;
  z-index: 1;
  line-height: 24px;

}

.custom-select .items-wrapper{
   max-height: 150px;
   overflow-y: scroll;
   padding: 8px 20px;
}

.custom-select .items-wrapper div {
  color: #595959;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
}

.custom-select .items-wrapper div:hover:not(.disabled) .checkbox-label {
  color: #56D08A;
}

.custom-select .items-new-user{
   padding: 8px 15px;
   border-top: 1px solid #CBCBCB;
   display: flex;
   justify-content: flex-end;
   color: #56D08A;
}

.selectHide {
  display: none;
}

.arrow-image {
  margin-top: 15px;
}

label {
  /* margin-bottom: 5px !important; */
  /* font-weight: 600; */
  align-items: center;
  display: flex;
}

.items .form-checkbox {
  display: none !important;
}

.items-wrapper label {
  cursor: pointer;
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
  padding-left: 20px;
}

.items-wrapper label:before,
.items-wrapper label:after {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 0% !important;
  transform: translate(-50%, -50%) !important;
  transition: all 0.3s ease 0s;
  transition-delay: 1ms;
  -webkit-transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease 0s;
  display: inline-block;
  background: url("@/assets/img/icons/icon-check-default.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.items-wrapper label:after {
  visibility: hidden;
  opacity: 0;
  background: url("@/assets/img/icons/icon-check-checked.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.items-wrapper .checkbox:not(.disabled) label:hover:before {
  background: url("@/assets/img/icons/icon-check-hover.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.items-wrapper input[data-checked="true"] + label:before {
  visibility: hidden;
  opacity: 0;
}

.items-wrapper input[data-checked="true"] + label:after {
  visibility: visible;
  opacity: 1;
}

.items-wrapper input[data-checked="false"] + label:before {
  visibility: visible;
  opacity: 1;

}

.items-wrapper input[data-checked="false"] + label:after {
   visibility: hidden;
   opacity: 0;
}

.search input{
   height: auto;
    min-height: 35px;
    background-color: #fff;
    border-radius: 6px;
    /* border: 1px solid #cbcbcb; */
    color: #666;
    padding-left: 0.7em;
    /* cursor: pointer; */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    min-height: 41px !important;
}

.search input::placeholder{
   color: #9ca3af;
}

.search input:focus{
   outline: none;
   /* border: 1px solid rgb(86, 208, 138); */
}

.no-results label{
   color: #303030;
   font-weight: 300;
   font-family: 'Archivo';
}

.placeholder{
  color: #9ca3af!important;
}
</style>
