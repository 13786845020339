<template>
  <div class="modal" v-if="showModal">
    <div class="modal-wrapper">
      <div class="p-6 bg-white shadow-2xl">
        <div class="flex">
          <div class="text-adviz-primary mr-2">
            <ProjectsIconSmall />
          </div>
          <div class="font-normal modal-title">{{ action }} {{ $t('modals.editCreateProjectModal.aProject') }}</div>
        </div>

        <div class="mt-2">
          <label for="inputText" class="flex gap-2">
            {{ $t('modals.editCreateProjectModal.projName')  }}
            <span
              v-if="v$.projectPayload.name && v$.projectPayload.name.required"
              class="text-red-500"
              >*</span
            >
          </label>
          <input
            type="text"
            class="border w-full rounded-lg p-2"
            :placeholder="this.$t('modals.editCreateProjectModal.projName')"
            v-model="projectPayload.name"
            :class="{ 'not-valid': v$.projectPayload.name.$errors.length }"
          />
          <FieldError
            v-if="v$.projectPayload.name.$error"
            :error="v$.projectPayload.name.$errors[0].$message"
          />
        </div>

        <div class="mt-2 mb-2">
          <TableFilterSelect
            :label="this.$t('modals.editCreateProjectModal.projLang')"
            :options="languageOptions"
            :placeholder="'Sélectionnez une langue'"
            :value="projectPayload.language"
            disabled="true"
            :required="v$.projectPayload.language && v$.projectPayload.language.required"
            @selection-changed="projectPayload.language = $event.value"
          />
        </div>

        <div class="mt-2 mb-2">
          <TableFilterSelect
            :label="this.$t('modals.editCreateProjectModal.orgName')"
            :disabled="project != null"
            :options="organizationOptions"
            :placeholder="'Sélectionnez une organisation'"
            :value="
              project != null
                ? project.assigned_organization_id
                : projectPayload.assigned_organization_id
            "
            :selectedByDefault="selectedOrg ? organizationOptions.find((option) => option.value == selectedOrg) : null"
            @selection-changed="projectPayload.assigned_organization_id = $event.value"
            :required="
              v$.projectPayload.assigned_organization_id &&
              v$.projectPayload.assigned_organization_id.required
            "
                  :addNewText="this.$t('filters.tableFilterSelect.addNewText')"
            @createNew="$emit('addNewOrg')"
          />
          <FieldError
            v-if="v$.projectPayload.assigned_organization_id.$error"
            :error="v$.projectPayload.assigned_organization_id.$errors[0].$message"
          />
        </div>

        <div class="mt-2 mb-2">
          <TableFilterSelect
          :label="this.$t('modals.editCreateProjectModal.product')"
            :disabled="project != null"
            :options="productOptions"
            :placeholder="'Sélectionnez un produit'"
            :value="selectedProduct"
            @selection-changed="projectPayload.product_group_id = $event.value"
            :class="{ 'not-valid': v$.projectPayload.product_group_id.$errors.length }"
            :required="v$.projectPayload.product_group_id && v$.projectPayload.product_group_id.required"
          />
          <FieldError
            v-if="v$.projectPayload.product_group_id.$error"
            :error="v$.projectPayload.product_group_id.$errors[0].$message"
          />
        </div>

        <p v-if="!projectIdx && errorMsg" class="text-red-500 font-semibold">
          {{ errorMsg }}
        </p>
        <p v-else-if="balance" class="font-semibold">
          {{ $t('modals.editCreateProjectModal.balance') }}: {{ balance }} {{ $t('modals.editCreateProjectModal.credit') }}
        </p>

        <div class="flex justify-between mt-4">
          <button @click="closeModal" class="close-modal">{{ $t('modals.editCreateProjectModal.cancelButton') }}</button>
          <button
            :disabled="!projectIdx && errorMsg || isSubmitting"
            @click="handleEditCreateProject"
            class="submit-button"
          >
          {{ $t('modals.editCreateProjectModal.submitButton') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getTodaysDate } from "@/helpers/getTodaysDate.js";
import TableFilterSelect from "@/components/organisms/matrices/TableFilterSelect.vue";
import ProjectsIconSmall from "@/components/atoms/icons/ProjectsIconSmall.vue";
import ProjectApi from "@/apis/ProjectApi";
import FieldError from "@/views/admin/components/FieldError.vue";
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import {ProductGroupTypes} from "@/helpers/productGroupTypes";
import {mapState} from "vuex";

export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  validations() {
    return {
      projectPayload: {
        name: {
          required: helpers.withMessage(this.$t('validations.required'), required),
          minLength: helpers.withMessage(
            this.$t('validations.minimal4chars'),
            (value) => value.length > 3
          )
        },
        assigned_organization_id: {
          required: helpers.withMessage(this.$t('validations.required'), required)
        },
        product_group_id: {
          required: helpers.withMessage(this.$t('validations.required'), required)
        }
      }
    }
  },
  components: {
    ProjectsIconSmall,
    TableFilterSelect,
    FieldError
  },
  props: {
    showModal: {
      type: Boolean,
      required: true
    },
    projectIdx: {
      type: Number,
      required: false
    },
    projects: {
      type: Array,
      required: true
    },
    organizationID: {
      type: Number,
      required: true
    },
    organizations: {
      type: Array,
      required: true
    },
    productBalances: {
      type: Array,
      required: true
    },
    selectedOrg: {
      type: Number,
      required: false
    }
  },
  watch: {
    showModal() {
      if (this.project != null && this.projectPayload.name == null) {
        this.projectPayload.name = this.project.name
        this.projectPayload.assigned_organization_id = this.project.assigned_organization_id
        this.projectPayload.product_group_id = this.project.product_group_id
      }
      if (!this.showModal) {
        this.projectPayload = {
          product_group_id: null,
          name: null,
          organization_id: this.organizationID,
          assigned_organization_id: null,
          language: 'fr'
        }
      }
    }
  },
  computed: {
    ...mapState(['productGroups']),
    action() {
      return this.projectIdx !== null ? this.$t('modals.editCreateProjectModal.action.edit')  : this.$t('modals.editCreateProjectModal.action.add')
    },
    formIsValid() {
      return (
        this.projectPayload.name != null &&
        this.projectPayload.name.length > 3 &&
        this.projectPayload.assigned_organization_id != null &&
        this.projectPayload.product_group_id != null
      )
    },
    errorMsg() {
      // TODO: Show balance error message
      if (this.balance === 0) {
        return this.$t('modals.editCreateProjectModal.noCredit')
      }
      return null
    },
    balance() {
      const selectedProduct = this.productOptions.find(
          (product) => {
            return product.value === this.projectPayload.product_group_id;
          }
      )
      if(selectedProduct){
        // TODO: Change to ProductGroup (?)
        if([ProductGroupTypes.ODASE, ProductGroupTypes.Values].includes(selectedProduct.value)) return null;
        return selectedProduct.balance;
      }
      return null
    },
    project() {
      if (this.projectIdx !== null && this.projects.length > 0) {
        // Find project based on projectIdx
        return this.projects.find((project) => {
          return project.id === this.projectIdx
        })
      }
      return null
    },
    projectStartDate() {
      return getTodaysDate()
    },
    productOptions() {
      const productGroups = []

      const currentOrgProductGroups = this.organizations.find(
        (org) => org.id == this.organizationID
      ).product_groups.map((el) => el.name);

      this.productGroups.forEach((group) => {
        if (!currentOrgProductGroups.includes(group.name)) {
          return
        }

        let balance = 0
        this.productBalances.otherCredits.forEach((credit) => {
          if (credit.product_id === 1) {
            balance += credit.balance
            credit.credit_usages.forEach((usage) => {
              balance -= usage.amount
            })
          }
        })
        productGroups.push({
          name: this.$t('productGroups.' + group.name),
          value: group.id,
          balance: balance
        })
      })

      // Group by product_group_id and sum balances under the same product_group_id
      // this.productBalances.forEach((product) => {
      //   const productGroup = productGroups.find(
      //     (group) => group.value === product.product_group_id
      //   )
      //   if (productGroup) {
      //     productGroup.balance += product.balance
      //   } else {
      //     productGroups.push({
      //       name: product.product_group?.name,
      //       value: product.product_group_id,
      //       balance: product.balance
      //     })
      //   }
      // })

      return productGroups
    },
    selectedProduct() {
      if (this.project != null) {
        return this.project.product_group_id
      }
      return this.projectPayload.product_group_id
    },
    organizationOptions() {
      return this.organizations
        .filter((organization) => organization.parent_id !== null) // exclude partner orgs from list of organizations
        .map((organization) => {
          return {
            name: organization.name,
            value: organization.id
          }
      })
    }
  },
  data() {
    return {
      isSubmitting: false,
      projectPayload: {
        product_group_id: null,
        name: null,
        organization_id: this.organizationID,
        assigned_organization_id: null,
        language: 'fr'
      },
      languageOptions: [
        { name: this.$t('modals.editCreateProjectModal.lang.fr'), value: 'fr' },
        { name: this.$t('modals.editCreateProjectModal.lang.en'), value: 'en' }
      ]
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
      this.v$.projectPayload.$reset()
    },
    async handleEditCreateProject() {
      this.v$.projectPayload.$touch()

      if (this.v$.projectPayload.$invalid) {
        return
      } else {
        this.isSubmitting = true
        if (this.projectIdx == null) {

          // trim values
          for (const key of Object.keys(this.projectPayload)) {
            if (typeof this.projectPayload[key] === 'string') {
              this.projectPayload[key] = this.projectPayload[key].trim();
            }
          }

          await ProjectApi()
            .createProject(this.projectPayload)
            .then((response) => {
             // console.log('response', response)
              this.$store.dispatch('SET_PROJECTS', this.organizationID)

              const projectName = this.projectPayload.name;
              const orgId = this.organizationID;
              const projectId = response.data.data.id;
              const projectLink = `/projects/${orgId}/${projectId}`;
              const proj = this.$t('modals.editCreateProjectModal.toasts.proj');
              const created = this.$t('modals.editCreateProjectModal.toasts.created');

              const message = `${proj} <a class="underline" href="${projectLink}">${projectName}</a> ${created}.`;

              this.$toasts.success(message);
            })
            .catch((error) => {
              console.log('error', error)
              this.$toasts.error(this.$t('modals.editCreateProjectModal.toasts.createdError'))
            })
        } else {
          await ProjectApi()
            .updateProject(this.projectIdx, { ...this.projectPayload, status: this.project.status })
            .then((response) => {
              console.log('response', response)
              this.$emit('updateProject', response.data.data)
              this.$store.dispatch('SET_PROJECTS', this.organizationID)
              this.$store.dispatch('SET_BALANCES_BY_PRODUCT_GROUPS', this.organizationID)

              const projectName = this.projectPayload.name;
              const orgId = this.organizationID;
              const projectId = response.data.data.id;
              const projectLink = `/projects/${orgId}/${projectId}`;
              const proj = this.$t('modals.editCreateProjectModal.toasts.proj');
              const edited = this.$t('modals.editCreateProjectModal.toasts.edited');

              const message = `${proj} <a class="underline" href="${projectLink}">${projectName}</a> ${edited}.`;

              this.$toasts.success(message);
            })
            .catch((error) => {
              console.log('error', error)
              this.$toasts.error(
                this.$t('modals.editCreateProjectModal.toasts.editError')
              )
            })
        }

        this.projectPayload = {
          product_group_id: null,
          name: null,
          organization_id: this.organizationID,
          assigned_organization_id: null,
          language: 'fr'
        }

        this.closeModal()
        this.isSubmitting = false
      }
    }
  }
}
</script>

<style scoped>
.tableFilterSelect {
  border-color: lightgray;
}

.modal {
  background-color: #14141482 !important;
  z-index: 10001;
  @apply inset-0
      fixed
      flex
      justify-center
      items-center
      bg-light-2 bg-opacity-50;
}

.modal-wrapper {
  @apply flex
      justify-center
      items-center
      fixed
      inset-0
      z-50;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  padding: 20px 0;
}

@media screen and (max-height: 800px) {
  .modal-wrapper {
    @apply items-start; /* Center-align the modal */
  }
}

.font-normal.modal-title {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #303030;
  margin-bottom: 8px;
}
.modal label {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #4f4f4f;
}

.modal input {
  margin-top: 3px;
  margin-bottom: 10px;
  min-height: 41px !important;
}

.modal .bg-white {
  width: 565px;
  border-radius: 8px;
  max-width: 90%;
}
.close-modal {
  background: transparent;
  padding: 5px 20px;
  @apply border border-red-500
            text-red-500
            font-semibold
            rounded-3xl
            cursor-pointer;
}

.submit-button {
  @apply bg-adviz-primary
            text-white
            font-semibold
            rounded-3xl
            cursor-pointer;
}

.modal button {
  padding: 5px 20px;
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.24px;
}
</style>
