<template>
  <nav
    class="left-sidebar min-w-max lg:min-h-screen"
    :class="isCollapsed ? 'collapsed' : 'not-collapsed'"
  >
    <div
      class="toggler w-full lg:h-20 flex justify-between items-center px-4 pt-2 lg:pt-1 lg:pb-5 top-0"
    >
      <router-link
        v-show="!isCollapsed"
        to="/"
        class="font-fraunces text-gray-logo text-lg leading-8"
        :class="{ 'ml-auto': isCollapsed }"
      >
        <span>{{ sidebarTitle }}</span>
      </router-link>
      <img
        src="@/assets/img/flat-icons/burger.svg"
        @click="isCollapsed = !isCollapsed"
        class="h-9 w-9 p-2 cursor-pointer"
      />
    </div>
    <div class="sticky top-20">
      <router-link
        v-for="(link, linkIdx) in linksByRoles"
        :key="`sidebar-link-${linkIdx}`"
        class="sidebar-link v-title-parent-right"
        :to="{ name: link.namedRoute }"
        :class="{ 'router-link-active': $route.path.includes(link.path) }"
        v-title="isCollapsed ? link.name : null"
      >
        <component
          :is="iconTypes[link.namedRoute]"
          :key="link.namedRoute"
          fill="currentColor"
          :class="{
            'pointer-events-none w-5 h-5 ml-4 mr-5': !isCollapsed,
            'pointer-events-none w-5 h-5 ml-5 mr-5': isCollapsed
          }"
        ></component>
        <span v-show="!isCollapsed">{{ link.name }}</span>
      </router-link>

      <!-- <template v-if="toolsByRoles.length > 0">
        <router-link
          v-for="(link, linkIdx) in toolsByRoles"
          :key="`sidebar-link-${linkIdx}`"
          class="sidebar-link v-title-parent-right"
          :to="{ name: link.namedRoute }"
          :class="{
            'router-link-active': $route.path.includes(link.path),
          }"
          v-title="isCollapsed ? link.name : null"
        >
          <component
            :is="iconTypes[link.namedRoute]"
            :key="link.namedRoute"
            fill="currentColor"
            :class="{
              'pointer-events-none w-5 h-5 ml-4 mr-5': !isCollapsed,
              'pointer-events-none w-5 h-5 ml-5 mr-5': isCollapsed
            }"
          ></component>
          <span v-show="!isCollapsed">{{ link.name }}</span>
        </router-link>
      </template> -->

      <SidebarAccordion
        v-if="manageLinksByRoles.length > 0"
        :title="$t('sidebar.navigation.manage')"
        icon="icon-sidebar-manage"
        :links="manageLinksByRoles"
        :isCollapsed="isCollapsed"
        @click="handleLinkClick"
      />

      <!-- TOOLS -->
      <!-- <AppAccordion class="clear border-t-2" :hideArrow="isCollapsed" v-if="toolsByRoles.length > 0"> // enable it if you want to show the tools accordion. Use this condition toolsByRoles.length > 0
          <template v-slot:title>
            <span
            class="sidebar-link v-title-parent-right pr-3"
            v-title="isCollapsed ? $t('sidebar.tools.title') : null"
          >
            <Tags
              fill="currentColor"
              :class="{
                'pointer-events-none w-5 h-5 ml-4 mr-5': !isCollapsed,
                'pointer-events-none w-5 h-5 ml-5 mr-5': isCollapsed
              }"
            />
            {{ !isCollapsed ? $t('sidebar.tools.title') : '' }}
          </span>
        </template>
        
        <template v-slot:content>
          <router-link
          v-for="(link, linkIdx) in toolsByRoles"
          :key="`sidebar-link-${linkIdx}`"
          class="sidebar-link v-title-parent-right !py-2"
          :to="{ name: link.namedRoute }"
          :class="{
            'router-link-active': $route.path.includes(link.path),
            'pl-5' : !isCollapsed,
          }"
            v-title="isCollapsed ? link.name : null"
            >
            <component
            :is="iconTypes[link.namedRoute]"
            :key="link.namedRoute"
            fill="currentColor"
            :class="{
              'pointer-events-none w-5 h-5 ml-4 mr-5': !isCollapsed,
              'pointer-events-none w-5 h-5 ml-5 mr-5': isCollapsed
            }"
            ></component>
            <span v-show="!isCollapsed">{{ link.name }}</span>
          </router-link>
        </template>
      </AppAccordion> -->
      <SidebarAccordion
        v-if="toolsByRoles.length > 0"
        :title="$t('sidebar.tools.title')"
        icon="icon-sidebar-tags"
        :links="toolsByRoles"
        :isCollapsed="isCollapsed"
        @click="handleLinkClick"
      />
    </div>

    <div class="links">
      <!-- <router-link
          to="/plans"
          class="sidebar-link "
        >
          <img
            src="@/assets/img/icons/icon-plans.svg"
            alt=""
            class="w-6 h-6 ml-4 mr-5"
            style="color: #4f4f4f"
          />
          <span v-show="!isCollapsed">Plans</span>
        </router-link> -->
      <!-- <router-link
          to="/products"
          class="sidebar-link -mb-2"
        >
          <img
            src="@/assets/img/icons/icon-help.svg"
            alt=""
            class="w-6 h-6 ml-4 mr-5"
            style="color: #4f4f4f"
          />
          <span v-show="!isCollapsed">All products</span>
        </router-link> -->
      <SidebarAccordion
        :title="$t('sidebar.help.help')"
        icon="icon-sidebar-help"
        :links="helpLinks"
        :isCollapsed="isCollapsed"
        @click="handleLinkClick"
      />
      <a
        href="mailto:support@marketpedia.ca"
        class="sidebar-link !py-3 v-title-parent-right"
        v-title="isCollapsed ? $t('sidebar.help.support') : null"
      >
        <img
          src="@/assets/img/icons/icon-sidebar-support.svg"
          alt=""
          class="w-6 h-6 ml-4 mr-5"
          style="color: #4f4f4f"
        />
        <span v-show="!isCollapsed">{{ $t('sidebar.help.support') }}</span>
      </a>
      <a
        href="https://marketpedia-all.customercase.com/forums/feedback?page=1"
        class="sidebar-link !py-3 mb-3 v-title-parent-right"
        target="_blank"
        v-title="isCollapsed ? $t('sidebar.help.support') : null"
      >
        <img
          src="@/assets/img/icons/icon-sidebar-suggestion.svg"
          alt=""
          class="w-6 h-6 ml-4 mr-5"
          style="color: #4f4f4f"
        />
        <span v-show="!isCollapsed">{{ $t('sidebar.help.feedback') }}</span>
      </a>

      <div class="ml-6" v-show="!isCollapsed">
        <p class="text-gray-light">© {{ currentYear }} Marketpedia</p>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapState } from 'vuex'

import Users from '@/components/atoms/icons/Users.vue'
import Home from '@/components/atoms/icons/Home.vue'
import Lock from '@/components/atoms/icons/Lock.vue'
import ProjectsIcon from '@/components/atoms/icons/ProjectsIcon.vue'
import DashboardIcon from '@/components/atoms/icons/DashboardIcon.vue'
import ClientOrg from '@/components/atoms/icons/ClientOrg.vue'
import AppAccordion from '../../molecules/AppAccordion.vue'
import Dashboard from '@/components/atoms/icons/Dashboard.vue'
import IconUsers from '@/components/atoms/icons/IconUsers.vue'
import Projects from '@/components/atoms/icons/Projects.vue'
import Tags from '@/components/atoms/icons/Tags.vue'
import { checkScreenWidth, responsivenessState } from '@/helpers/questionnaires/mobileUtils.js'
import SidebarAccordion from '../../molecules/SidebarAccordion.vue'
import TrackerApi from '@/apis/TrackerApi'

export default {
  components: {
    AppAccordion,
    Lock,
    ProjectsIcon,
    DashboardIcon,
    Tags,
    SidebarAccordion
  },
  setup() {
    const iconTypes = {
      Admin: Dashboard,
      Projects: ProjectsIcon,
      Surveys: Home,
      Dashboard: DashboardIcon,
      OrganizationList: ClientOrg,
      UserListOld: Users,
      UserList: Users,
      SuperAdminKontrol: Lock,
      Track: Lock,
      ClientOrg: ClientOrg,
      ProjectList: Projects,
      // Dashboard: Dashboard,
      Tags: Tags,
      WordCloud: Tags,
      CoachingTools: Tags,
      Reports: Projects
    }
    return { iconTypes }
  },
  data() {
    return {
      mobile: null,
      tablet: null,
      smallDesktopUp: null,
      showModal: 0, // Variable to track which modal to show
      currentYear: new Date().getFullYear(),
      links: [
        {
          name: this.$t('sidebar.navigation.admin'),
          path: 'admin',
          namedRoute: 'Admin',
          allowedRoles: [3, 4] // Temp remove of 1, 2, 6
        },
        {
          path: 'dashboard',
          namedRoute: 'Dashboard',
          name: this.$t('sidebar.navigation.dashboard'),
          allowedRoles: [1, 2, 6],
          includedToPlay: false
        },
        {
          path: 'projects',
          namedRoute: 'Projects',
          name: this.$t('sidebar.navigation.projects'),
          allowedRoles: [1, 2, 6],
          includedToPlay: true,
          excludeFromSimplified: true
        },
        {
          path: 'surveys',
          namedRoute: 'Surveys',
          name: this.$t('sidebar.navigation.surveys'),
          allowedRoles: [1, 2, 6],
          includedToPlay: false,
          excludeFromAdvanced: true
        },
        {
          name: this.$t('sidebar.navigation.organizations'),
          namedRoute: 'OrganizationList',
          allowedRoles: [1]
        },
        {
          name: this.$t('sidebar.navigation.reports'),
          path: 'reports',
          namedRoute: 'Reports',
          allowedRoles: [3, 4]
        },
        {
          name: this.$t('sidebar.navigation.superAdminKontrol'),
          path: 'super-adm-control',
          namedRoute: 'SuperAdminKontrol',
          allowedRoles: [1]
        },
        {
          name: this.$t('sidebar.navigation.superAdminStats'),
          path: 'track',
          namedRoute: 'Track',
          allowedRoles: [1]
        }
      ],
      toolsLinks: [
        {
          name: this.$t('sidebar.tools.wordCloud'),
          icon: 'icon-sidebar-tags',
          url: 'wordcloud',
          allowedRoles: [1, 2, 3, 4, 6]
        },
        {
          name: this.$t('sidebar.tools.coachingTools'),
          icon: 'icon-sidebar-tags',
          url: 'coaching-tools',
          allowedRoles: [1, 2, 3, 4, 6],
          excludeFromEnvs: ['play', 'staging', 'production']
        }
      ],
      helpLinks: [
        {
          name: this.$t('sidebar.help.sublinks.analyse.name'),
          url: this.$t('sidebar.help.sublinks.analyse.url'),
          target: '_blank'
        },
        {
          name: this.$t('sidebar.help.sublinks.stratPos.name'),
          url: this.$t('sidebar.help.sublinks.stratPos.url'),
          target: '_blank'
        },
        {
          name: this.$t('sidebar.help.sublinks.odase.name'),
          url: this.$t('sidebar.help.sublinks.odase.url'),
          target: '_blank'
        }
      ],
      manageLinks: [
        {
          name: this.$t('sidebar.navigation.users'),
          icon: 'icon-sidebar-users',
          url: 'users',
          allowedRoles: [1, 2, 6]
        },
        {
          name: this.$t('sidebar.navigation.clients'),
          icon: 'icon-sidebar-clients',
          url: 'clients',
          allowedRoles: [1, 2, 6]
        },
        {
          name: this.$t('sidebar.navigation.tags'),
          icon: 'icon-sidebar-tags',
          url: 'tags',
          allowedRoles: [1, 2, 6]
        },
        {
          name: this.$t('transaction.title'),
          icon: 'dollar',
          url: 'transactions',
          allowedRoles: [1, 6],
          includedToPlay: false
        }
      ],
      isCollapsed: false
    }
  },
  created() {
    const routerNames = this.$router.options.routes.map((route) => route.name)
    this.links = this.links.filter((link) => routerNames.includes(link.namedRoute))
  },
  mounted() {
    this.checkScreenWidthListener()
    window.addEventListener('resize', this.checkScreenWidthListener)
    if (this.playEnvironment || !this.smallDesktopUp) {
      this.isCollapsed = true
    }
    this.$emit('isCollapsed', this.isCollapsed)

    const roleId = this.auth.currentUser.roles[0].id
  },
  unmounted() {
    window.removeEventListener('resize', this.checkScreenWidthListener)
  },
  computed: {
    ...mapState(['auth', 'organizations']),
    organization() {
      return this.organizations.find(
        (organization) => organization.id === this.auth.currentUser.organization_id
      )
    },
    simplified() {
      return this.organization?.simplified
    },
    //Return viewable links by role
    linksByRoles() {
      const roleId = this.auth.currentUser.roles[0].id
      if (this.playEnvironment) {
        return this.links.filter(
          (link) => link.allowedRoles.includes(roleId) && link.includedToPlay
        )
      }

      if (this.simplified) {
        return this.links.filter(
          (link) => link.allowedRoles.includes(roleId) && link.excludeFromSimplified !== true
        )
      }
      // Advanced
      return this.links.filter(
        (link) => link.allowedRoles.includes(roleId) && link.excludeFromAdvanced !== true
      )
    },

    toolsByRoles() {
      const roleId = this.auth.currentUser.roles[0].id
      if (this.playEnvironment) {
        return this.toolsLinks.filter(
          (link) => link.allowedRoles.includes(roleId) && link.includedToPlay && !link?.excludeFromEnvs?.includes(import.meta.env.MODE)
        )
      }
      return this.toolsLinks.filter((link) => link.allowedRoles.includes(roleId) && !link?.excludeFromEnvs?.includes(import.meta.env.MODE))
    },
    manageLinksByRoles() {
      const roleId = this.auth.currentUser.roles[0].id
      if (this.playEnvironment) {
        return this.manageLinks.filter(
          (link) => link.allowedRoles.includes(roleId) && link.includedToPlay !== false
        )
      }
      return this.manageLinks.filter((link) => link.allowedRoles.includes(roleId))
    },
    hasAllowedRole() {
      return (roleId) => {
        return this.auth.currentUser.roles.some((role) => role.id === roleId)
      }
    },
    playEnvironment() {
      return import.meta.env.MODE === 'play'
    },
    sidebarTitle() {
      switch (import.meta.env.MODE) {
        case 'development':
          return 'marketpedia local'
        case 'play':
          return 'marketpedia play'
        default:
          return 'marketpedia'
      }
    },
    responsivenessState() {
      return responsivenessState
    }
  },
  methods: {
    checkScreenWidthListener() {
      checkScreenWidth()
      this.mobile = responsivenessState.value.isMobile
      this.tablet = responsivenessState.value.isTablet
      this.smallDesktopUp = responsivenessState.value.isSmallDesktopUp
    },
    handleLinkClick(link) {
      TrackerApi().trackButtonClick({
        from: 'sidebar',
        to: link.url,
        name: link.name,
        category: 'Help'
      })
    }
  },
  watch: {
    isCollapsed: function (val) {
      this.$emit('isCollapsed', val)
    },
    mobile: function (val) {
      if (val) {
        this.isCollapsed = true
      }
    },
    tablet: function (val) {
      if (val) {
        this.isCollapsed = true
      }
    }
  }
}
</script>

<style scoped>
@import '../../../assets/styles/sidebar.css';
</style>
