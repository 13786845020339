import { findKey } from 'lodash';

export const QuestionnairePrice = {
    'pos-dg' : 1,
    'pos-dg-2' : 1,
    'pos-staff' : 1,
    'pos-consumer' : 1,
    's5' : 1,
    'd3' : 1,
    'pos-staff-2' : 1,
    'pos-consumer-2' : 1,
    'pos-consumer-3' : 1,
    'pos-consumer-4' : 1,
    'pos-distributor' : 1,
    'pos-retailer' : 1,
    'pos-supplier' : 1,
    'odase': 125,
    'odase-express': 125,
    'values-presonal': 50,
}

// Get the questionnaire type from the value
export const getQuestionnairePriceByType = (type) => {
    return QuestionnairePrice[type];
}
